import * as React from "react"
import { Link } from "gatsby";
import { navigate } from 'gatsby-link';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ContactUsImage from '../svg/Medical-BG9.svg';

function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}


export default class ContactUs extends React.Component{

    constructor(props) {
        super(props)
        this.state = { isValidated: false }
      }
    
      handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
      }
    
      handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...this.state,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
      }

    render(){

    return(
        <Layout>
             <SEO title="Contact Us"/>
             <main>
                <div className="py-24 bg-blue-100 sm:py-32">
                    <div className="max-w-md mx-auto pl-4 pr-8 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:px-8">
                        <h1 className="text-5xl leading-10 font-extrabold tracking-tight text-blue-900 text-center sm:text-center sm:text-5xl sm:leading-none lg:text-6xl">Get <span className="text-blue-500">In Touch</span></h1>
                        
                    </div>
                </div>

                <div className="relative bg-white">

                <div className="mt-5 lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <ContactUsImage className="h-64 w-full object-cover lg:absolute lg:h-full" alt=""/>
                    </div>
                </div>
                
                <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
                    <div className="lg:pr-8">
                    <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                        <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                        Let's work together
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                        Whether you're just getting started or looking to upgrade exising digital assets, we'll help you craft the optimal digital strategy for your healthcare practice.
                        </p>

                        <form name="contact-form" onSubmit={this.handleSubmit} method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/thanks" className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <input type="hidden" name="bot-field" onChange={this.handleChange} />
                            <div>
                                <label for="first_name" className="block text-sm font-medium text-gray-700">First name</label>
                                <div className="mt-1">
                                <input type="text" name="first_name" id="first_name" autocomplete="given-name" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div>
                                <label for="last_name" className="block text-sm font-medium text-gray-700">Last name</label>
                                <div className="mt-1">
                                <input type="text" name="last_name" id="last_name" autocomplete="family-name" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label for="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <div className="mt-1">
                                <input id="email" name="email" type="email" autocomplete="email" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label for="company" className="block text-sm font-medium text-gray-700">Company</label>
                                <div className="mt-1">
                                <input type="text" name="company" id="company" autocomplete="organization" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                <label for="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                                <span id="phone_description" className="text-sm text-gray-500">Optional</span>
                                </div>
                                <div className="mt-1">
                                <input type="text" name="phone" id="phone" autocomplete="tel" aria-describedby="phone_description" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                <label for="how_can_we_help" className="block text-sm font-medium text-gray-700">How can we help you?</label>
                                <span id="how_can_we_help_description" className="text-sm text-gray-500">Max. 500 characters</span>
                                </div>
                                <div className="mt-1">
                                <textarea id="how_can_we_help" name="how_can_we_help" aria-describedby="how_can_we_help_description" rows="4" onChange={this.handleChange} className="block w-full shadow-sm sm:text-sm focus:ring-grape-500 focus:border-grape-500 border-gray-300 rounded-md"></textarea>
                                </div>
                            </div>
                        
                            <div className="sm:col-span-2">
                                <label for="how_did_you_hear_about_us" className="block text-sm font-medium text-gray-700">How did you hear about us?</label>
                                <div className="mt-1">
                                <input type="text" name="how_did_you_hear_about_us" id="how_did_you_hear_about_us" onChange={this.handleChange} className="shadow-sm focus:ring-grape-500 focus:border-grape-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
                                </div>
                            </div>
                            <div className="text-right sm:col-span-2">
                                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grape-500">
                                Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </main>
        </Layout>
    )
    }
}
